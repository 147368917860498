import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Badge } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EventIcon from '@material-ui/icons/Event';
import EventNoteIcon from '@material-ui/icons/EventNote';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import moment from 'moment';
import paymentproof from "../assets/proofofpay.png";
import AccandDec from "../assets/AAD.png";
import {MdBackup} from "react-icons/md";
import {AiOutlineFolder} from "react-icons/ai";
import Loading from '../components/Loading';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  card: {
    minWidth: 275,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
    backgroundColor: '#F7D060',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    
  },
  drawerPaper: {
    backgroundColor: '#F7D060', 
    width: drawerWidth,
  },
}));

export default function AcceptDecline() {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [unreadCount, setUnreadCount] = useState(parseInt(localStorage.getItem('unreadCount')) || 0);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // When the component mounts, wait for a short duration and then show the content
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 300); // You can adjust the duration as needed

    // Clean up the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      fetch('https://hettrrms-server.onrender.com/api/notifications')
        .then((response) => response.json())
        .then((data) => {
          const newUnreadCount = data.filter(notification => !notification.read && !localStorage.getItem(`notification_${notification.id}`)).length;
          setUnreadCount(newUnreadCount);
          localStorage.setItem('unreadCount', newUnreadCount);
        })
        .catch((error) => {
          console.error(error);
        });
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);
  const navigate = useNavigate();

  const [bookings, setBookings] = useState([]);
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    const fetchBookings = async () => {
      const response = await fetch("https://hettrrms-server.onrender.com/api/bookings?status=pending");
      if (response.ok) {
        const data = await response.json();
        setBookings(data.bookings);
      }
    };
    fetchBookings();
  }, []);

  useEffect(() => {
    const fetchRequests = async () => {
      const response = await fetch('https://hettrrms-server.onrender.com/api/requests?request_status=pending');
      if (response.ok) {
        const data = await response.json();
        setRequests(data.requests);
      }
    };

    fetchRequests();
  }, []);

  const handleAccept = async (bookingId) => {
    const response = await fetch(`https://hettrrms-server.onrender.com/api/bookings/${bookingId}/accept`, { method: "POST" });
    if (response.ok) {
      swal("Successfully accepted the reservation", "", "success");
      const newBookings = bookings.filter((booking) => booking.id !== bookingId);
      setBookings(newBookings);
      if (newBookings.length === 0) {
        const bookingContainer = document.getElementById("booking-container");
        bookingContainer.style.display = "none";
      }
  
      const notifyResponse = await fetch(`https://hettrrms-server.onrender.com/api/bookings/${bookingId}/accepted/notify`, { method: "POST" });
      if (notifyResponse.ok) {
        console.log("Notification sent successfully");
      } else {
        console.error("Failed to send notification: ", notifyResponse.statusText);
      }
    } else {
      console.error("Failed to accept reservation: ", response.statusText); 
    }
  };
  
  
  const handleDecline = async (bookingId) => {
    const response = await fetch(`https://hettrrms-server.onrender.com/api/bookings/${bookingId}/decline`, { method: "POST" });
    if (response.ok) {
      swal("Reservation Declined", "", "success");
      const newBookings = bookings.filter((booking) => booking.id !== bookingId);
      setBookings(newBookings);
      if (newBookings.length === 0) {
        const bookingContainer = document.getElementById("booking-container");
        bookingContainer.style.display = "none";
      }
      const notifyResponse = await fetch(`https://hettrrms-server.onrender.com/api/bookings/${bookingId}/declined/notify`, { method: "POST" });
      if (notifyResponse.ok) {
        console.log("Notification sent successfully");
      } else {
        console.error("Failed to send notification: ", notifyResponse.statusText);
      }
    } else {
      console.error("Failed to accept reservation: ", response.statusText);
    }
  };

  const handleAcceptRequest = async ( bookingId, departureDate, returnDate) => {
    try {
      const updatedDepartureDate = moment(departureDate).format('YYYY-MM-DD');
      const updatedReturnDate = moment(returnDate).format('YYYY-MM-DD');
  
      const response = await fetch('https://hettrrms-server.onrender.com/api/update-booking-date', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          
          bookingId,
          departureDate: updatedDepartureDate,
          returnDate: updatedReturnDate,
        }),
      });
  
      if (response.ok) {
        // Booking date successfully updated
        swal('Success', 'Booking date updated successfully.', 'success');
        // Refresh the bookings list or update the state
        // ...
      } else {
        // Error updating booking date
        swal('Error', 'Failed to update booking date.', 'error');
      }
    } catch (error) {
      console.error('Error updating booking date:', error);
      swal('Error', 'Failed to update booking date.', 'error');
    }
  };
  

  const handleDeclineRequest = async (requestId) => {
    try {
      const response = await fetch(`https://hettrrms-server.onrender.com/api/decline-request/${requestId}`, {
        method: 'POST',
      });

      if (response.ok) {
        // Request declined successfully
        swal('Success', 'Request declined successfully.', 'success');
        // Refresh the requests list or update the state
        // ...
      } else {
        // Error declining request
        swal('Error', 'Failed to decline request.', 'error');
      }
    } catch (error) {
      console.error('Error declining request:', error);
      swal('Error', 'Failed to decline request.', 'error');
    }
  };

  if (!showContent) {
    return <Loading />;
  }
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar style={{background:'#3C4048'}}
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Admin Dashboard
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerPaper]: open,
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <List>
  <ListItem button key="Dashboard" onClick={() => {navigate('/admin')}}>
    <ListItemIcon>
      <DashboardIcon />
    </ListItemIcon>
    <ListItemText primary="Dashboard" />
  </ListItem>
  <ListItem button key="User" onClick={() => {navigate('/Showuser')}}>
    <ListItemIcon>
      <PeopleIcon />
    </ListItemIcon>
    <ListItemText primary="User" />
  </ListItem>
  <ListItem button key="Add Package" onClick={() => {navigate('/AddPackages')}}>
    <ListItemIcon>
      <AddBoxIcon />
    </ListItemIcon>
    <ListItemText primary="Add Package" />
  </ListItem>
  <ListItem button key="Reservation" onClick={() => {navigate('/Reservation')}}>
    <ListItemIcon>
      <EventNoteIcon />
    </ListItemIcon>
    <ListItemText primary="Reservation" />
  </ListItem>
  <ListItem button key="Notification" onClick={() => {navigate('/AdminNotification')}}>
    <ListItemIcon>
    <Badge badgeContent={unreadCount} color="secondary">
      <NotificationsIcon/>
    </Badge>
    </ListItemIcon>
    <ListItemText primary="Notification" />
  </ListItem>
  <ListItem button key="Travel/Tour Schedule" onClick={() => {navigate('/TravelandTourSchedule')}}>
    <ListItemIcon>
      <EventIcon />
    </ListItemIcon>
    <ListItemText primary="Travel/Tour Schedule"/>
  </ListItem>
  <ListItem button key="Accept and Decline" onClick={() => {navigate('/AcceptDecline')}}>
    <ListItemIcon>
    <img src={AccandDec} alt='proof' style={{width:'22px', color:'gray'}} />
    </ListItemIcon>
    <ListItemText primary="Accept and Decline"/>
  </ListItem>
  <ListItem button key="Customers Payment Proof " onClick={() => {navigate('/CustomersPayment')}}>
    <ListItemIcon>
    <img src={paymentproof} alt='proof' style={{width:'22px', color:'gray'}} />
    </ListItemIcon>
    <ListItemText primary="Customer Payment Proof"/>
  </ListItem>
  <ListItem button key="Clients Record" onClick={() => {navigate('/ClientsRecord')}}>
    <ListItemIcon>
      <AiOutlineFolder size={25} />
    </ListItemIcon>
    <ListItemText primary="Clients Record"/>
  </ListItem>
  <ListItem button key="Business Partner Record" onClick={() => {navigate('/BusinessPartnerRecord')}}>
    <ListItemIcon>
      <BusinessCenterIcon />
    </ListItemIcon>
    <ListItemText primary="Business Partner Record"/>
  </ListItem>
  <ListItem button key="Backup and Restore" onClick={() => {navigate('/Backup&Restore')}}>
    <ListItemIcon>
    <MdBackup size={25} />
    </ListItemIcon>
    <ListItemText primary="Backup and Restore" />
  </ListItem>
  <ListItem button key="Account" onClick={() => {navigate('/AdminProfile')}}>
    <ListItemIcon>
      <AccountCircleIcon/>
    </ListItemIcon>
    <ListItemText primary="Account" />
  </ListItem>
</List>


      </Drawer>
      <main className={`${classes.content} ${showContent ? 'fadeAnim' : ''}`}>
        <div className={classes.toolbar} /> 
        <div style={{ marginTop: "2rem" }}>

        <div  style={{fontFamily:'Raleway, sans-serif', fontSize:'30px', position:'relative', left:'750px', fontWeight:'bold', }}>
       Accept and Decline
      </div>
       {bookings.map((booking) => (
        <div
          key={booking.id}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "1rem",
            backgroundColor: "#f2f2f2",
            borderRadius: "5px", 
            marginBottom: "1rem",
          }}
        > 
          <h3 style={{ marginBottom: "1rem" }}>Booking Status</h3>
          <ul style={{
          listStyle: "none",
          padding: 0,
          marginLeft: 50,
          textAlign: "left",
        }}>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>UserId:</strong> {booking.user_id}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Name:</strong> {booking.name1}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Email:</strong> {booking.email}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Destination:</strong> {booking.destination}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Departure Date:</strong> {' '} {moment(booking.departureDate).format('MMMM DD, YYYY')}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Return Date:</strong>{' '} {moment(booking.returnDate).format('MMMM DD, YYYY')}
          </div>
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Number of Travelers:</strong> {booking.numTravelers}
          </div>
          {booking.travelers.map((traveler, index) => (
              <span key={index}>
                <div><strong>Name:</strong> {traveler.name}</div>
                 <div><strong>Age:</strong> {traveler.age}</div> <br />
              </span>
            ))}
          </ul>  
          <div style={{ display: "flex", marginBottom: "1rem" }}>
            <strong>Total Payment:</strong> {booking.totalPayment}
          </div>
          <div style={{ display: "flex" }}>
            <button style={{margin:'5px',backgroundColor:'#002B5B',color:'#fff', padding:'10px 15px', border:'none', borderRadius:'5px', fontSize:'16px', cursor:"pointer"}} onClick={() => handleAccept(booking.id)}>Accept</button>
            <button style={{margin:'5px',backgroundColor:'#D61355',color:'#fff', padding:'10px 15px', border:'none', borderRadius:'5px', fontSize:'16px', cursor:"pointer"}} onClick={() => handleDecline(booking.id)}>Decline</button>
          </div>
        </div>
        
      ))}
       
      {requests.map((request) => (
         <div
         key={request.id}
         style={{
           display: "flex",
           flexDirection: "column",
           alignItems: "center",
           padding: "1rem",
           backgroundColor: "#f2f2f2",
           borderRadius: "5px", 
           marginBottom: "1rem",
         }}
       > 
         <h3 style={{ marginBottom: "1rem" }}>Request for Change Date</h3>
         <ul style={{
          listStyle: "none",
          padding: 0,
          marginLeft: 50,
          textAlign: "left",
        }}>
         <div style={{ display: "flex", marginBottom: "1rem" }}>
           <strong>UserId:</strong> {request.user_id}
         </div>
         <div style={{ display: "flex", marginBottom: "1rem" }}>
           <strong>BookingId:</strong> {request.booking_id}
         </div>
         
         <div style={{ display: "flex", marginBottom: "1rem" }}>
           <strong>Departure Date:</strong> {' '} {moment(request.departure_date).format('MMMM DD, YYYY')}
         </div>
         <div style={{ display: "flex", marginBottom: "1rem" }}>
           <strong>Return Date:</strong>{' '} {moment(request.return_date).format('MMMM DD, YYYY')}
         </div>
         </ul>
         <div style={{ display: "flex" }}>
           <button style={{margin:'5px',backgroundColor:'#002B5B',color:'#fff', padding:'10px 15px', border:'none', borderRadius:'5px', fontSize:'16px', cursor:"pointer"}}     onClick={() => handleAcceptRequest(request.booking_id, request.departure_date, request.return_date)}>Accept</button>
           <button style={{margin:'5px',backgroundColor:'#D61355',color:'#fff', padding:'10px 15px', border:'none', borderRadius:'5px', fontSize:'16px', cursor:"pointer"}}      onClick={() => handleDeclineRequest(request.id)}>Decline</button>
         </div>
       </div>
     ))}
     
    </div>
      </main>
    </div>
  );
}
